import Swiper, { Autoplay, Navigation, Pagination, Thumbs } from 'swiper';

const slider = '.hero__background-slider';

Swiper.use([Navigation, Pagination, Thumbs, Autoplay]);

const heroCarousel = new Swiper(slider, {
    slideActiveClass: 'is-active',
    slidesPerView: 1,
    disableOnInteraction: false,
    autoplay: {
        delay: 1000,
    },
    direction: 'horizontal',
    navigation: {
        nextEl: '.hero__slider-arrow-next',
        prevEl: '.hero__slider-arrow-prev',
    },
    pagination: {
        el: '.hero__slider-pagination',
        type: 'bullets',
        bulletClass: 'hero__slider-bullet',
        bulletActiveClass: 'active',
        clickable: true,
    },
});

heroCarousel.on('slideChange', e => {
    const activeIndex = e.realIndex + 1;
    const currentSlideDomElement = document.querySelector(
        '.hero__current-slide'
    );
    if (!currentSlideDomElement) return;
    currentSlideDomElement.textContent = activeIndex;
});

let isClicked = false;
